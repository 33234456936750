import { useDisclosure } from '@chakra-ui/hooks';
import DeleteModal from 'components/actions/Delete';
import Card from 'components/card';
import {
    archiveCustomer,
    getCustomerPage,
    shareMagicLink,
    updatePageActivated,
} from 'interfaces/customer';
import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import CustomerStats from './components/customerStats';
import GoBack from 'components/actions/GoBack';
import { MdOpenInNew, MdOutlineShare, MdSettings } from 'react-icons/md';
import CustomerInfoPreview from './components/CustomerInfoPreview';
import LastInvoices from './components/LastInvoices';
import LastOrders from './components/LastOrders';
import ShareOrderModal from './components/shareOrderModal';
import { toast } from 'react-toastify';
import YearlyCA from './components/YearlyCA';

const EditCustomer = () => {
    const navigate = useNavigate();
    const params = useParams();
    const { isOpen, onClose } = useDisclosure();
    const {
        isOpen: isOpenShare,
        onOpen: onOpenShare,
        onClose: onCloseShare,
    } = useDisclosure();
    const [customer, setCustomer] = useState<any>(null);
    const [disabledCommandPage, setDisabledCommandPage] = useState(false);

    const initCustomer = async (id: string) => {
        const res = await getCustomerPage(id);
        if (res) {
            setCustomer(res);
            return;
        }
        navigate('/customers');
    };

    const handleSendCommandPage = async () => {
        if (disabledCommandPage) {
            toast.error("L'accès boutique a déjà été envoyée récemment.");
            return;
        }
        setDisabledCommandPage(true);
        const res = await shareMagicLink(customer.mail);
        if (res) {
            setDisabledCommandPage(true);
            toast.success(`Accès boutique envoyée à ${customer.mail}`);
        }
        onCloseShare();
    };

    const lastVisitToString = (lastVisit: string) => {
        return `${new Date(customer.lastVisit).toLocaleDateString()} à
            ${new Date(customer.lastVisit)
                .toLocaleTimeString()
                .split(':')
                .slice(0, 2)
                .join(':')}`;
    };

    useEffect(() => {
        if (localStorage.getItem('token') === null) {
            navigate('/login');
        }
        initCustomer(params.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="mt-1 col-span-12">
                <GoBack text="Mes clients" />
            </div>
            <div className="mt-2 grid grid-cols-12 gap-3">
                <CustomerStats id={customer && customer.id} />
                <ShareOrderModal
                    isOpen={isOpenShare}
                    onClose={onCloseShare}
                    onSend={handleSendCommandPage}
                    magicLink={customer && customer.magicLink}
                />
                <DeleteModal
                    isOpen={isOpen}
                    onClose={onClose}
                    description="Êtes-vous sûr de vouloir archiver ce client ?"
                    context="un client"
                    archived={true}
                    deleteFunction={() => {
                        archiveCustomer(params.id);
                        navigate('/customers');
                    }}
                />
                <CustomerInfoPreview customer={customer} />
                <Card
                    extra={
                        'w-full !p-4 border col-span-12 sm:col-span-6 relative'
                    }
                >
                    {/* Header */}
                    <div className="relative w-full px-[8px]">
                        {customer && customer.mail && (
                            <div className="absolute right-0 top-0">
                                <button
                                    className="flex items-center gap-1 text-sm text-gray-700"
                                    onClick={() =>
                                        navigate('/settings/command-page')
                                    }
                                >
                                    <MdSettings />
                                    <span>Réglages</span>
                                </button>
                            </div>
                        )}
                        <div className="flex items-center gap-2">
                            <h4 className="text-lg font-medium text-navy-700 dark:text-white">
                                Boutique B2B
                            </h4>
                            <div className="flex my-auto">
                                <label className="inline-flex items-center cursor-pointer">
                                    <input
                                        type="checkbox"
                                        checked={
                                            customer?.isOrderingPageActivated
                                        }
                                        className="sr-only peer"
                                        onChange={async () => {
                                            const newState =
                                                !customer.isOrderingPageActivated;
                                            setCustomer({
                                                ...customer,
                                                isOrderingPageActivated:
                                                    newState,
                                            });
                                            const res =
                                                await updatePageActivated({
                                                    _id: customer._id,
                                                    isOrderingPageActivated:
                                                        newState,
                                                });
                                            if (res) {
                                                toast.info(
                                                    `Boutique B2B ${
                                                        newState
                                                            ? 'activée'
                                                            : 'désactivée'
                                                    } pour ce client.`,
                                                );
                                            }
                                        }}
                                    ></input>
                                    <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                </label>
                            </div>
                        </div>
                        {customer && customer.lastVisit ? (
                            <p className="mt-1 text-sm text-gray-600">
                                Visité pour la dernière fois le{' '}
                                {lastVisitToString(customer.lastVisit)}
                            </p>
                        ) : (
                            <p className="mt-1 text-sm text-gray-600">
                                Il s'agit de l'espace de commande dédié à ce
                                client.
                            </p>
                        )}
                    </div>
                    {customer && customer.mail ? (
                        <div className="mt-2 grid grid-cols-1 sm:grid-cols-3 gap-2">
                            <button
                                className="linear flex cursor-pointer items-center justify-center rounded-xl border border-[#01499b] px-2 py-1 text-xs text-[#01499b] transition duration-200 hover:bg-brand-600/5 active:bg-red-100 dark:active:bg-red-50 dark:active:opacity-80"
                                onClick={() =>
                                    window.open(
                                        `https://app.toola.eu/command/${customer?.magicLink}`,
                                        '_blank',
                                    )
                                }
                            >
                                <MdOpenInNew className="h-3 w-3 mr-1" />
                                Accéder à la page
                            </button>
                            <button
                                className="linear flex cursor-pointer items-center justify-center rounded-xl border border-[#01499b] px-2 py-2 text-xs text-[#01499b] transition duration-200 hover:bg-brand-600/5 active:bg-red-100 dark:active:bg-red-50 dark:active:opacity-80"
                                onClick={onOpenShare}
                            >
                                <MdOutlineShare className="h-3 w-3 mr-1" />
                                Partager la page
                            </button>
                            <button
                                className="linear flex cursor-pointer items-center justify-center rounded-xl border border-[#01499b] px-2 py-2 text-xs text-[#01499b] transition duration-200 hover:bg-brand-600/5 active:bg-red-100 dark:active:bg-red-50 dark:active:opacity-80"
                                onClick={() =>
                                    navigate(
                                        `/customer-pricing/${customer?._id}`,
                                    )
                                }
                            >
                                <MdSettings className="h-3 w-3 mr-1" />
                                Gérer les prix
                            </button>
                        </div>
                    ) : (
                        <p className="ml-2 mt-1 text-sm text-gray-600 font-bold">
                            {'>'} Renseignez l'adresse mail du prospect pour
                            activer sa boutique B2B.
                        </p>
                    )}
                </Card>
                <div className="col-span-12 sm:col-span-6">
                    {customer && customer.lastOrders && (
                        <LastOrders tableData={customer?.lastOrders} />
                    )}
                </div>
                <div className="col-span-12 sm:col-span-6">
                    {customer && customer.lastInvoices && (
                        <LastInvoices tableData={customer?.lastInvoices} />
                    )}
                </div>
                <div className="col-span-12">
                    <YearlyCA id={customer && customer.id} />
                </div>
            </div>
        </>
    );
};

export default EditCustomer;
