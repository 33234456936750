import { useEffect, useState } from 'react';
import Card from 'components/card';

import { createColumnHelper } from '@tanstack/react-table';
import { getCustomerAnalytics } from 'interfaces/customer';
import { useParams } from 'react-router-dom';
import { IoInformationCircleOutline } from 'react-icons/io5';

// const columns = columnsDataCheck;
const YearlyCA = (props: { id: string }) => {
    const params = useParams();
    const id = params.id || props.id;
    const [data, setData] = useState([]);
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);

    const getAnalytics = async () => {
        if (!id) return;
        const res = await getCustomerAnalytics(id);
        console.log(res);
        if (res) setData(res.CAMonthPerMonth);
    };

    useEffect(() => {
        getAnalytics();
        console.log('id', id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const mois = [
        'janvier',
        'février',
        'mars',
        'avril',
        'mai',
        'juin',
        'juillet',
        'août',
        'septembre',
        'octobre',
        'novembre',
        'décembre',
    ];

    return (
        <Card extra={'w-full h-full px-6 pb-6 sm:overflow-x-auto border'}>
            <div className="flex items-center space-x-2">
                <h1 className="mt-5 text-lg text-navy-700 font-medium">
                    Chiffre d'affaires HT annuel
                </h1>
                <div
                    className="mt-5 relative"
                    onMouseEnter={() => setIsTooltipVisible(true)}
                    onMouseLeave={() => setIsTooltipVisible(false)}
                >
                    <IoInformationCircleOutline className="w-4 h-4 text-gray-500 cursor-pointer" />
                    {isTooltipVisible && (
                        <div className="absolute text-xs left-0 mt-2 w-64 p-2 bg-gray-700 text-white rounded-md shadow-lg z-10">
                            Le chiffre d'affaires est attribué à chaque mois en
                            fonction de la date de livraison.
                        </div>
                    )}
                </div>
            </div>

            <div className="mt-2 overflow-x-scroll xl:overflow-x-hidden">
                <table className="min-w-full bg-white rounded-xl">
                    <thead>
                        <tr className="!border-px !border-gray-400">
                            {mois.map((moi, index) => (
                                <th
                                    key={index}
                                    className="cursor-pointer rounded-sm border-b border-gray-200 pt-4 pb-2 pr-4 text-start dark:border-white/30"
                                >
                                    <p className="text-sm font-bold text-gray-600 dark:text-white">
                                        {moi}
                                    </p>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {data?.map((month, index) => (
                                <td
                                    key={index}
                                    className="min-w-[90px] border-white/0 py-2 px-2"
                                >
                                    <p className="text-xs font-bold text-navy-700 dark:text-white">
                                        {Number(month.ca).toFixed()}€
                                    </p>
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
                {data.length === 0 && (
                    <div className="flex items-center justify-center h-32">
                        <p className="text-gray-400 dark:text-white">
                            Aucune donnée disponible
                        </p>
                    </div>
                )}
            </div>
        </Card>
    );
};

export default YearlyCA;
