import Card from 'components/card';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getProductions } from 'interfaces/productions';
import { getProducts } from 'interfaces/products';
import { useDisclosure } from '@chakra-ui/hooks';
import ProductionPreviewModal from './components/ProductionPreview';
import GoBack from 'components/actions/GoBack';
import ProductSummary from 'views/orders/listOrders/components/productsSummary';
import OrderTable from './components/OrderTable';
import { getPaginatedOrders } from 'interfaces/order';
import InputField from 'components/fields/InputField';
import SaveStatus from 'components/actions/SaveStatus';

const PrepareProduction = () => {
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [date, setDate] = useState(new Date().toISOString().slice(0, 10));

    const [product, setProduct] = useState<any>({});
    const [items, setItems] = useState<any[]>([]);
    const [orders, setOrders] = useState<any[]>(null);
    const [saveStatus, setSaveStatus] = useState('none');
    const [products, setProducts] = useState<any[]>(null);
    const [productions, setProductions] = useState<any[]>(null);

    const initProductions = async () => {
        const prods = await getProductions();
        if (!prods) {
            navigate('/auth/login');
            return;
        }
        setProductions(prods.data);
    };

    const initProducts = async () => {
        const prods = await getProducts();
        if (!prods) {
            navigate('/auth/login');
            return;
        }
        setProducts(prods.data);
    };

    const onProduction = async (prod: any) => {
        setProductions([prod, ...productions]);
        onClose();
    };

    function mergeOrderItems(orders: any) {
        const mergedItemsMap = new Map();

        orders.forEach((order: any) => {
            order.items.forEach((item: any) => {
                const productId = item.productId;
                if (mergedItemsMap.has(productId)) {
                    const existingItem = mergedItemsMap.get(productId);
                    existingItem.quantity =
                        parseInt(existingItem.quantity) +
                        parseInt(item.quantity);
                    existingItem.brutWeight += item.brutWeight;
                    existingItem.netWeight += item.netWeight;
                    existingItem.orderIds.push(order._id);
                    existingItem.companies.push(order.company);
                } else {
                    mergedItemsMap.set(productId, {
                        ...item,
                        quantity: parseInt(item.quantity),
                        productId: item.productId,
                        orderIds: [order._id],
                        companies: [order.company],
                        display: false,
                    });
                }
            });
        });

        return Array.from(mergedItemsMap.values());
    }

    const initOrders = async () => {
        if (!date || date === undefined) return;
        const res = await getPaginatedOrders(1, 500, 'pending', '', 'all');
        if (res) {
            const orders = res.data.filter(
                (order: any) =>
                    new Date(order.deliveryDate).toISOString().split('T')[0] ===
                    date,
            );
            setOrders(orders);
            const items = mergeOrderItems(orders);
            setItems(items);
        }
    };

    useEffect(() => {
        initOrders();
    }, [date]);

    useEffect(() => {
        if (localStorage.getItem('token') === null) {
            navigate('/login');
        }
        initProductions();
        initProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <ProductionPreviewModal
                isOpen={isOpen}
                onClose={onClose}
                product={product}
                productName={
                    products?.find((p) => p._id === product.product)?.name
                }
                onProduction={onProduction}
            />
            <div className="mt-2">
                <GoBack />
            </div>
            <div className="mt-2 grid  grid-cols-12 gap-5">
                <div className="col-span-12">
                    <ProductSummary />
                </div>
                <div className="col-span-12">
                    <Card extra={'mt-1 w-full px-8 border'}>
                        <div className="absolute right-2 top-2">
                            <SaveStatus status={saveStatus} />
                        </div>
                        <div className="h-full w-full">
                            <div>
                                <h4 className="mt-8 sm:mt-2 text-lg font-medium text-navy-700 dark:text-white">
                                    Produits commandés
                                </h4>
                                <InputField
                                    label=""
                                    placeholder="01/01/2021"
                                    id="deliveryDate"
                                    type="date"
                                    extra="w-[140px] mx-2"
                                    value={date}
                                    onChange={(e: any) =>
                                        setDate(e.target.value)
                                    }
                                    inputSmall={true}
                                />
                                {items ? (
                                    <>
                                        <>
                                            {items && (
                                                <OrderTable
                                                    items={items}
                                                    handleQtyChange={null}
                                                    handlePriceChange={null}
                                                    handleDiscountChange={null}
                                                    handleCommentChange={null}
                                                    handleDiscountTypeChange={
                                                        null
                                                    }
                                                    setSaveStatus={
                                                        setSaveStatus
                                                    }
                                                    onDelete={null}
                                                />
                                            )}
                                            {items && items.length === 0 && (
                                                <div className="">
                                                    <p className="mt-5 text-lg text-gray-600 text-center">
                                                        Aucun produit
                                                        sélectionné dans cette
                                                        commande
                                                    </p>
                                                </div>
                                            )}
                                        </>
                                    </>
                                ) : (
                                    <div className="flex justify-center">
                                        <p className="text-lg text-gray-600">
                                            Veuillez sélectionner un client
                                            avant de continuer
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </>
    );
};

export default PrepareProduction;
