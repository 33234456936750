import Card from 'components/card';
import InputField from 'components/fields/InputField';
import Switch from 'components/switch';

const BrutProductForm = (props: {
    state: number;
    newProduct: any;
    setState: any;
    handleProductChange: any;
}) => {
    const { state, newProduct, setState, handleProductChange } = props;

    const switchSellProduct = () => {
        handleProductChange(
            'category',
            newProduct.category === 'hidden' ? '' : 'hidden',
        );
    };

    return (
        <div className="px-3">
            <Card extra={'mt-3 w-full !p-5 border'}>
                {/* inputs */}
                <h4 className="text-lg font-medium text-navy-700 dark:text-white">
                    Produit brut
                </h4>
                <div className="mt-4 grid grid-cols-12 gap-3">
                    <div className="col-span-4">
                        <InputField
                            label="Nom*"
                            placeholder="Jus d'orange"
                            id="name"
                            type="text"
                            onChange={(e: any) => {
                                handleProductChange('name', e.target.value);
                                if (state === 0) setState(1);
                            }}
                            inputSmall={true}
                        />
                    </div>
                    <div className="col-span-4">
                        <InputField
                            label="Réference*"
                            placeholder="JO-00001"
                            value={newProduct.ref}
                            id="ref"
                            type="text"
                            onChange={(e: any) => {
                                handleProductChange('ref', e.target.value);
                                if (state === 0) setState(1);
                            }}
                            inputSmall={true}
                        />
                    </div>
                    <div className="col-span-4">
                        <InputField
                            label="Unité*"
                            placeholder="Kg"
                            value={newProduct.unity}
                            id="unity"
                            type="text"
                            onChange={(e: any) => {
                                handleProductChange('unity', e.target.value);
                                if (state === 0) setState(1);
                            }}
                            inputSmall={true}
                        />
                    </div>
                </div>
                {/* full width inputs */}

                <div className="mt-2 grid grid-cols-12 gap-3">
                    <div className="col-span-6 lg:col-span-4 ml-2">
                        <InputField
                            label="Prix d'achat (€)"
                            placeholder="0"
                            id="purchasePrice"
                            type="number"
                            onChange={(e: any) => {
                                handleProductChange(
                                    'purchasePrice',
                                    e.target.value,
                                );
                            }}
                            inputSmall={true}
                        />
                    </div>
                    <div className="col-span-6 lg:col-span-4">
                        <InputField
                            label={`Prix de vente (€)`}
                            placeholder="15"
                            id="price"
                            type="number"
                            onChange={(e: any) => {
                                handleProductChange('price', e.target.value);
                                if (state === 0) setState(1);
                            }}
                            inputSmall={true}
                        />
                    </div>
                    <div className="col-span-6 lg:col-span-4">
                        <InputField
                            label="Seuil d'alerte"
                            placeholder={`150 ${newProduct.unity}`}
                            id="alert"
                            type="number"
                            onChange={(e: any) => {
                                handleProductChange('alert', e.target.value);
                                if (state === 0) setState(1);
                            }}
                            inputSmall={true}
                        />
                    </div>
                    <div className="col-span-6 lg:col-span-4 ml-2">
                        <InputField
                            label={`DLC (en jours)`}
                            placeholder="J conservation après production"
                            id="daysOfConservation"
                            type="number"
                            onChange={(e: any) => {
                                handleProductChange(
                                    'daysOfConservation',
                                    e.target.value,
                                );
                                if (state === 0) setState(1);
                            }}
                            inputSmall={true}
                        />
                    </div>
                    <div className="col-span-6 lg:col-span-4">
                        <InputField
                            label={`Alerte DLC (en jours)`}
                            placeholder="Être averti X jours avant une DLC"
                            id="dlcAlert"
                            type="number"
                            onChange={(e: any) => {
                                handleProductChange('dlcAlert', e.target.value);
                                if (state === 0) setState(1);
                            }}
                            inputSmall={true}
                        />
                    </div>
                    <div className="col-span-6 lg:col-span-4 ml-2">
                        <InputField
                            label="TVA (%)*"
                            placeholder="5.5"
                            id="vat"
                            type="number"
                            onChange={(e: any) => {
                                handleProductChange('vat', e.target.value);
                            }}
                            inputSmall={true}
                        />
                    </div>
                    <div className="col-span-6 lg:col-span-4 ml-2">
                        <InputField
                            label="Poids brut (Kg)"
                            placeholder="0"
                            id="brutWeight"
                            type="number"
                            onChange={(e: any) => {
                                handleProductChange(
                                    'brutWeight',
                                    e.target.value,
                                );
                            }}
                            inputSmall={true}
                        />
                    </div>
                    <div className="col-span-6 lg:col-span-4 ml-2">
                        <InputField
                            label="Poids net (Kg)"
                            placeholder="0"
                            id="netWeight"
                            type="number"
                            onChange={(e: any) => {
                                handleProductChange(
                                    'netWeight',
                                    e.target.value,
                                );
                            }}
                            inputSmall={true}
                        />
                    </div>
                    <div
                        className={`ml-5 col-span-12 lg:col-span-4 mt-2 flex items-center justify-between`}
                    >
                        <label
                            htmlFor={'switch'}
                            className="text-navy-700 text-xs font-medium"
                        >
                            Vente possible*
                            <p className={`mt-3 text-xs text-gray-600`}>
                                Le produit brut est disponbile à la vente.
                            </p>
                        </label>
                        <div>
                            <Switch
                                onChange={switchSellProduct}
                                id={'id'}
                                checked={newProduct.category === ''}
                                color="toola"
                            />
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    );
};

export default BrutProductForm;
