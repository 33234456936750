import {
    MdEuro,
    MdOutlinePersonPin,
    MdOutlineShoppingBasket,
    MdPallet,
} from 'react-icons/md';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import { useDisclosure } from '@chakra-ui/hooks';
import { GiCargoCrate } from 'react-icons/gi';
import { TbMoneybag, TbPigMoney } from 'react-icons/tb';

import MiniStatistics from 'components/card/MiniStatistics';
import LosingEstimation from './components/ProfitEstimation';
import StockValue from './components/ProjectStatus';
import Card from 'components/card';
import StockAlert from '../smartHome/components/Weather';
import { getDashboardAnalytics } from 'interfaces/user';
import { getStockAlerts } from 'interfaces/stock';
import AverageBasket from './components/averageBasket';
import TopCustomers from 'views/admin/main/account/billing/components/TopCustomers';
import SleepingCustomers from 'views/admin/main/account/billing/components/sleepingCustomers';
import OrdersLines from './components/OrdersLines';
import InputField from 'components/fields/InputField';
import BenefitLines from './components/BenefitLines';
import TopCustomersMap from './components/TopCustomersMap';
import VolumeTable from './components/volumeTable';
import { useShepherdTour } from 'react-shepherd';

function getToday() {
    return new Date().toISOString().split('T')[0];
}

function getSevenDaysAgo() {
    const d = new Date();
    d.setDate(d.getDate() - 7);
    return d.toISOString().split('T')[0];
}

const steps: any = [
    {
        id: 'intro',
        attachTo: { element: '.first-element', on: 'bottom' },
        beforeShowPromise: function () {
            return new Promise<void>(function (resolve) {
                setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                }, 500);
            });
        },
        buttons: [
            {
                classes: 'shepherd-button-secondary',
                text: 'Pas maintenant',
                type: 'cancel',
            },
            {
                classes: 'shepherd-button-primary',
                text: 'Commencer',
                type: 'next',
            },
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        scrollTo: false,
        cancelIcon: {
            enabled: true,
        },
        title: 'Se lancer avec Toola 🚀',
        text: [
            'Dans 10 minutes vos premiers clients pourront déjà passer commande automatiquement !',
        ],
        when: {
            show: () => {
                console.log('show step');
            },
            hide: () => {
                console.log('hide step');
            },
        },
    },
    {
        title: '1/5: Paramétrer votre compte',
        text: 'Vous pouvez à tout moment modifier vos paramètres via ce menu déroulant',
        attachTo: {
            element: '.step-user-menu',
            on: 'left',
        },
        buttons: [
            {
                classes: 'shepherd-button-primary',
                text: 'Paramétrer mon compte',
                action() {
                    window.location.href = '/settings';
                },
            },
        ],
        id: 'step-user-menu',
    },
    // ...
];

const Dashboard = () => {
    const navigate = useNavigate();
    const [data, setData] = useState<any>(null);
    const [startDate, setStartDate] = useState<any>(getSevenDaysAgo());
    const [endDate, setEndDate] = useState<any>(getToday());
    const [allDates, setAllDates] = useState<any>([]);
    const [alerts, setAlerts] = useState<any>([]);
    const [alertModalOpened, setAlertModalOpened] = useState<boolean>(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const tourOptions = {
        defaultStepOptions: {
            cancelIcon: {
                enabled: true,
            },
        },
        useModalOverlay: true,
    };
    const tour = useShepherdTour({ tourOptions, steps });

    const initDashboardData = async (startDate: string, endDate: string) => {
        const res: any = await getDashboardAnalytics(startDate, endDate);
        if (res.error) {
        }
        setData(res.data);
    };

    const initStockAlerts = async () => {
        const res = await getStockAlerts();
        if (res.error) return;
        setAlerts(res.data);
        localStorage.setItem('alertsNbr', res.data.length);
    };

    const AlertModal = (props: { isOpen: any; onClose: any }) => {
        const { isOpen, onClose } = props;
        return (
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay className="bg-[#000] !opacity-30" />
                <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] top-[12vh]">
                    <ModalBody>
                        <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[450px] flex flex-col !z-[1004]">
                            <h1 className="mb-[20px] mx-auto text-2xl font-bold">
                                Attention !
                            </h1>
                            <p className="mb-5 text-lg">Problèmes détectés:</p>
                            {alerts.filter(
                                (alert: any) => alert.type === 'quantity-alert',
                            ).length > 0 && (
                                <p className="text-lg">
                                    - Un ou plusieurs seuils d'alerte atteints.
                                </p>
                            )}
                            {alerts.filter(
                                (alert: any) => alert.type === 'dlc-alert',
                            ).length > 0 && (
                                <p className="text-lg">
                                    - Certaines DLC ont été dépassées.
                                </p>
                            )}
                            {alerts.filter(
                                (alert: any) => alert.type === 'dlc-warning',
                            ).length > 0 && (
                                <p className="text-lg">
                                    - Certaines DLC arrivent à expiration.
                                </p>
                            )}
                            {alerts.filter(
                                (alert: any) => alert.type === 'delivery-issue',
                            ).length > 0 && (
                                <p className="text-lg">
                                    - Problèmes de livraison ont été rencontrés.
                                </p>
                            )}
                            <div className="mt-7 flex gap-2">
                                <button
                                    onClick={onClose}
                                    className="linear rounded-xl border-2 border-red-500 px-5 py-3 text-base font-medium text-red-500 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                                >
                                    Fermer
                                </button>
                                <button
                                    className="linear col-span-12 ml-3 flex items-center justify-center rounded-xl bg-[#0260CB] px-3 py-3 text-base font-medium text-white hover:bg-[#01499b] active:bg-[#024086] dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 sm:col-span-6 lg:col-span-4 xl:col-span-3 xl:col-start-12"
                                    onClick={() => navigate('/alerts')}
                                >
                                    Visualiser les alertes ({alerts.length})
                                </button>
                            </div>
                        </Card>
                    </ModalBody>
                </ModalContent>
            </Modal>
        );
    };

    useEffect(() => {
        const isSubAccount = localStorage.getItem('ownerId');

        if (data && data.productsNbr === 0 && !isSubAccount) {
            tour.start();
            localStorage.setItem('onboarding-step', '1');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (data && alerts.length > 0 && !alertModalOpened) {
            // Delay to avoid modal opening on page load
            const delayDebounceFn = setTimeout(() => {
                onOpen();
                setAlertModalOpened(true);
                return () => clearTimeout(delayDebounceFn);
            }, 500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, alerts]);

    useEffect(() => {
        if (localStorage.getItem('token') === null) navigate('/auth/login');
        initDashboardData(startDate, endDate);
        initStockAlerts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const dates = [];
            const start = new Date(startDate);
            const end = new Date(endDate);
            while (start <= end) {
                dates.push(new Date(start));
                start.setDate(start.getDate() + 1);
            }

            setAllDates(dates);
            initDashboardData(startDate, endDate);
        }, 300);
        return () => clearTimeout(delayDebounceFn);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate]);

    return (
        <div className="mt-4 flex h-full w-full flex-col gap-[20px] rounded-[20px] sm:mt-3 xl:flex-row">
            <AlertModal isOpen={isOpen} onClose={onClose} />
            <div className="h-full w-full rounded-[20px]">
                <div className="col-span-9 h-full w-full rounded-t-2xl xl:col-span-6">
                    {/* Statistics */}
                    <div className="z-0 col-span-12 grid h-full w-full grid-cols-2 gap-5 md:grid-cols-2 xl:grid-cols-4">
                        <div id="step-one">
                            <MiniStatistics
                                name="CA du jour"
                                value={data ? `${data.todayBenefit} €` : '-'}
                                icon={
                                    <MdOutlineShoppingBasket color="#0260CB" />
                                }
                                iconBg="bg-lightPrimary"
                            />
                        </div>
                        <div>
                            <MiniStatistics
                                name="CA de la période"
                                value={
                                    data ? `${data.benefitPerPeriod} €` : '-'
                                }
                                icon={<MdEuro color="#0260CB" />}
                                iconBg="bg-lightPrimary"
                            />
                        </div>
                        <div>
                            <MiniStatistics
                                name="Volume de la période"
                                value={
                                    data ? `${data.volumePerPeriod} Kg` : '-'
                                }
                                icon={<TbMoneybag color="#0260CB" />}
                                iconBg="bg-lightPrimary"
                            />
                        </div>
                        <div>
                            <MiniStatistics
                                name="Commandes / période"
                                value={
                                    data ? `${data.totalOrdersPerPeriod}` : '-'
                                }
                                icon={<TbPigMoney color="#0260CB" />}
                                iconBg="bg-lightPrimary"
                            />
                        </div>
                    </div>
                    {/* Filter */}
                    <div className="ml-5 mt-2 right flex w-ful gap-2">
                        <InputField
                            placeholder="01/01/2021"
                            id="deliveryDate"
                            type="date"
                            value={startDate}
                            onChange={(e: any) => setStartDate(e.target.value)}
                            inputSmall={true}
                        />
                        <p className="my-auto text-xl">-</p>
                        <div className="col-span-1">
                            <InputField
                                placeholder="01/01/2021"
                                id="deliveryDate"
                                type="date"
                                value={endDate}
                                onChange={(e: any) =>
                                    setEndDate(e.target.value)
                                }
                                inputSmall={true}
                            />
                        </div>
                    </div>
                    {/* Main data */}
                    <div className="mt-5 grid w-full grid-cols-8 gap-5">
                        <div className="col-span-12">
                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
                                <BenefitLines
                                    data={data?.sparkLinesBenefit}
                                    allDates={allDates}
                                />
                                <OrdersLines
                                    data={data?.sparklinesOrders}
                                    allDates={allDates}
                                />
                                <AverageBasket
                                    data={data?.sparklinesBasket}
                                    allDates={allDates}
                                />
                                <TopCustomers data={data?.topCustomers} />
                                <TopCustomersMap data={data?.topCustomers} />
                                <SleepingCustomers
                                    data={data?.inactiveCustomers}
                                />
                                <div className="col-span-1 md:col-span-2">
                                    <VolumeTable data={data?.volumeTable} />
                                </div>
                                <StockValue data={data} />
                                <StockAlert data={data} />
                                <LosingEstimation data={data} />
                            </div>
                        </div>
                        <div className="col-span-12">
                            <div className="grid grid-cols-1 gap-5 md:grid-cols-3">
                                <Card
                                    extra={
                                        'flex items-center justify-center py-12 w-full text-4xl text-gray-700 border'
                                    }
                                >
                                    <MdPallet className="h-10 w-10" />
                                    <button
                                        className="linear mx-auto mt-5 flex w-[70%] items-center justify-center rounded-xl bg-[#0260CB] py-2 font-dm text-base font-medium text-white transition duration-200 hover:bg-[#01499b] active:bg-[#024086]"
                                        onClick={() =>
                                            navigate('/create-order')
                                        }
                                    >
                                        Créer une commande
                                    </button>
                                </Card>
                                <Card
                                    extra={
                                        'flex items-center justify-center py-12 w-full text-4xl text-gray-700 border'
                                    }
                                >
                                    <MdOutlinePersonPin className="h-10 w-10" />
                                    <button
                                        className="linear mx-auto mt-5 flex w-[70%] items-center justify-center rounded-xl bg-[#0260CB] py-2 font-dm text-base font-medium text-white transition duration-200 hover:bg-[#01499b] active:bg-[#024086]"
                                        onClick={() =>
                                            navigate('/create-customer')
                                        }
                                    >
                                        Créer un client
                                    </button>
                                </Card>
                                <Card
                                    extra={
                                        'flex items-center justify-center py-12 w-full text-4xl text-gray-700 border'
                                    }
                                >
                                    <GiCargoCrate className="h-10 w-10" />
                                    <button
                                        className="linear mx-auto mt-5 flex w-[70%] items-center justify-center rounded-xl bg-[#0260CB] py-2 font-dm text-base font-medium text-white transition duration-200 hover:bg-[#01499b] active:bg-[#024086]"
                                        onClick={() =>
                                            navigate(
                                                '/delivery/delivery-list?f=1',
                                            )
                                        }
                                    >
                                        Mes livraisons
                                    </button>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
