import InputField from 'components/fields/InputField';
import { bulkEditOrderLotNumber } from 'interfaces/order';
import { useEffect, useState } from 'react';
import { getTotalHT } from 'views/orders/createOrders/utils';

const ItemsTable = (props: {
    items: any;
    handleQtyChange: any;
    handlePriceChange: any;
    handleDiscountTypeChange: any;
    handleDiscountChange: any;
    handleCommentChange: any;
    setSaveStatus: any;
    onDelete: any;
}) => {
    const { items, handlePriceChange, setSaveStatus } = props;

    const [refreshableitems, setRefreshableitems] = useState<any>(items);
    const [lotNumberItems, setLotNumberItems] = useState<any>([]);

    const handleLotNumberChange = (item: any, value: any) => {
        const items = [...lotNumberItems];
        for (let i = 0; i < item.orderIds.length; i++) {
            if (
                items.find(
                    (x: any) =>
                        x.index === `${item.productId}-${item.orderIds[i]}`,
                )
            ) {
                items.find(
                    (x: any) =>
                        x.index === `${item.productId}-${item.orderIds[i]}`,
                ).lotNumber = value;
            } else {
                items.push({
                    productId: item.productId,
                    lotNumber: value,
                    orderId: item.orderIds[i],
                    index: `${item.productId}-${item.orderIds[i]}`,
                });
            }
        }
        setLotNumberItems(items);
        const newItems = [...refreshableitems];
        newItems.map((x: any) => {
            if (x.productId === item.productId) {
                x.lotNumber = value;
            }
        });
        setRefreshableitems(newItems);
    };

    const displayOrderList = (item: any, value: any) => {
        const items = [...refreshableitems];

        const itemToDisplay: any = items.find(
            (x: any) => x.productId === item._id,
        );

        if (itemToDisplay) {
            items.find((x: any) => x.productId === item.productId).display =
                value;
        }
        setRefreshableitems([...refreshableitems]);
    };

    useEffect(() => {
        setSaveStatus('saving');
        const delayDebounceFn = setTimeout(async () => {
            const res = await bulkEditOrderLotNumber(lotNumberItems);
            if (res) setSaveStatus('saved');
            else setSaveStatus('error');
        }, 2000);
        return () => clearTimeout(delayDebounceFn);
    }, [lotNumberItems]);

    useEffect(() => {
        setRefreshableitems(items);
    }, [items]);

    return (
        <table className="w-full">
            <thead className="hidden sm:contents">
                <tr key={0} className="!bitems-px !bitems-gray-400">
                    <th
                        key={1}
                        colSpan={0}
                        onClick={() => {}}
                        className="mt-[20px] cursor-pointer bitems-b bitems-gray-200 pb-2 px-[10px] pt-4 text-start text-sm dark:bitems-white/10"
                    >
                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                            Produit
                        </p>
                    </th>
                    <th
                        key={2}
                        colSpan={0}
                        onClick={() => {}}
                        className="mt-[20px] cursor-pointer bitems-b bitems-gray-200 pb-2 px-[10px] pt-4 text-start text-sm dark:bitems-white/10"
                    >
                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                            Quantité
                        </p>
                    </th>
                    <th
                        key={3}
                        colSpan={0}
                        onClick={() => {}}
                        className="mt-[20px] cursor-pointer bitems-b bitems-gray-200 pb-2 px-[10px] pt-4 text-start text-sm dark:bitems-white/10"
                    >
                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                            Prix unitaire HT
                        </p>
                    </th>
                    <th
                        key={5}
                        colSpan={0}
                        onClick={() => {}}
                        className="mt-[20px] cursor-pointer bitems-b bitems-gray-200 pb-2 px-[10px] pt-4 text-start text-sm dark:bitems-white/10"
                    >
                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                            N de lot
                        </p>
                    </th>
                    <th
                        key={6}
                        colSpan={0}
                        onClick={() => {}}
                        className="mt-[20px] cursor-pointer bitems-b bitems-gray-200 pb-2 px-[10px] pt-4 text-start text-sm dark:bitems-white/10"
                    >
                        <p className="text-sm font-bold text-gray-600 dark:text-white">
                            Prix total HT
                        </p>
                    </th>
                </tr>
            </thead>
            <tbody>
                {refreshableitems.map((item: any, index: number) => (
                    <tr key={`${item.name}-${index}`}>
                        <td
                            key={`${item.name}-${index}-product`}
                            className="hidden sm:table-cell bitems-b text-sm dark:bitems-white/10 px-[10px] py-[10px]"
                        >
                            <div>
                                <p className="text-sm font-bold text-navy-700 dark:text-white xl:leading-3">
                                    {`${item.orderIds.length} ${
                                        item.orderIds.length > 1
                                            ? 'commandes'
                                            : 'commande'
                                    } avec le produit:`}
                                </p>
                                <p className="mt-2 text-sm font-medium text-navy-700 dark:text-white xl:leading-3">
                                    {item.name}
                                </p>
                                <p className="font-base mt-[2px] text-gray-600">
                                    {item.ref}
                                </p>
                                {/*  List of companies with a button for opening the order url in a new tab */}
                                {item.display &&
                                    item.companies.map(
                                        (company: any, index: number) => (
                                            <div key={index} className="flex">
                                                <p className="font-base mt-[2px] text-gray-600">
                                                    {company.name}
                                                </p>
                                                <a
                                                    href={`/order/${item.orderIds[index]}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="text-blue-500"
                                                >
                                                    {'>'} Voir la commande de{' '}
                                                    {company}
                                                </a>
                                            </div>
                                        ),
                                    )}
                                {!item.display ? (
                                    <button
                                        onClick={() =>
                                            displayOrderList(item, true)
                                        }
                                        className="text-blue-500"
                                    >
                                        {'+'} Afficher les commandes
                                    </button>
                                ) : (
                                    <button
                                        onClick={() =>
                                            displayOrderList(item, false)
                                        }
                                        className="mt-2 text-blue-500"
                                    >
                                        {'-'} Masquer les commandes
                                    </button>
                                )}
                            </div>
                        </td>
                        <td
                            key={`${item.name}-${index}-quantity`}
                            className="hidden sm:table-cell bitems-b text-sm dark:bitems-white/10 px-[10px] py-[10px]"
                        >
                            <div className="flex gap-[5px] items-center">
                                <InputField
                                    key={`${item.name}-${index}-qty`}
                                    extra="w-[70px]"
                                    placeholder="Quantité"
                                    id={item.name + '_qty'}
                                    type="number"
                                    value={item.quantity}
                                    onChange={(e: any) => {}}
                                    disabled={true}
                                    inputSmall={true}
                                />
                                <p className="text-sm font-bold text-navy-700 dark:text-white">
                                    {item.unity}
                                </p>
                            </div>
                        </td>
                        <td
                            key={`${item.name}-${index}-price`}
                            className="hidden sm:table-cell bitems-b text-sm dark:bitems-white/10 px-[10px] py-[10px]"
                        >
                            <div className="flex gap-[5px] items-center">
                                <InputField
                                    key={`${item.name}-${index}-qty`}
                                    extra="w-[120px]"
                                    placeholder="Prix unitaire"
                                    id={item.name + '_up'}
                                    type="number"
                                    value={item.price}
                                    onChange={(e: any) => {
                                        handlePriceChange(item, e.target.value);
                                    }}
                                    disabled={true}
                                    inputSmall={true}
                                />
                                <p className="text-sm font-bold text-navy-700 dark:text-white">
                                    €
                                </p>
                            </div>
                        </td>
                        <td
                            key={`${item.name}-${index}-lot-number`}
                            className="hidden sm:table-cell bitems-b text-sm dark:bitems-white/10 px-[10px] py-[10px]"
                        >
                            <div className="flex gap-[5px] items-center">
                                <InputField
                                    key={`${item.name}-${index}-ln`}
                                    extra="w-[140px]"
                                    placeholder="10403997"
                                    id={'lotNumber'}
                                    type="text"
                                    value={item.lotNumber}
                                    onChange={(e: any) =>
                                        handleLotNumberChange(
                                            item,
                                            e.target.value,
                                        )
                                    }
                                    inputSmall={true}
                                    disabled={false}
                                />
                            </div>
                        </td>
                        <td
                            key={`${item.name}-${index}-total-price`}
                            className="hidden sm:table-cell bitems-b text-sm dark:bitems-white/10 px-[10px] py-[10px]"
                        >
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                                {getTotalHT([item]).toFixed(2)} €
                            </p>
                        </td>
                        <td
                            key={`${item.name}-${index}-mobile`}
                            className="sm:hidden bitems-b text-sm dark:bitems-white/10 py-[10px] w-full"
                        >
                            <div>
                                <div className="flex gap-[5px] items-center">
                                    <p className="text-base font-bold text-navy-700 dark:text-white xl:leading-3">
                                        {item.quantity}
                                        {item.unity} {item.name}
                                    </p>
                                </div>
                                <p className="font-base text-gray-600">
                                    {item.ref}
                                </p>
                                {item.display &&
                                    item.companies.map(
                                        (company: any, index: number) => (
                                            <div key={index} className="flex">
                                                <p className="font-base mt-[2px] text-gray-600">
                                                    {company.name}
                                                </p>
                                                <a
                                                    href={`/order/${item.orderIds[index]}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="text-blue-500"
                                                >
                                                    {'>'} Voir la commande de{' '}
                                                    {company}
                                                </a>
                                            </div>
                                        ),
                                    )}
                                {!item.display ? (
                                    <button
                                        onClick={() =>
                                            displayOrderList(item, true)
                                        }
                                        className="text-blue-500"
                                    >
                                        {'+'} Afficher les commandes
                                    </button>
                                ) : (
                                    <button
                                        onClick={() =>
                                            displayOrderList(item, false)
                                        }
                                        className="mt-2 text-blue-500"
                                    >
                                        {'-'} Masquer les commandes
                                    </button>
                                )}
                                <div className="flex gap-[5px] items-center">
                                    <InputField
                                        key={`${item.name}-${index}-ln`}
                                        extra="w-[140px]"
                                        placeholder="10403997"
                                        id={'lotNumber'}
                                        type="text"
                                        value={item.lotNumber}
                                        onChange={(e: any) =>
                                            handleLotNumberChange(
                                                item,
                                                e.target.value,
                                            )
                                        }
                                        inputSmall={true}
                                        disabled={false}
                                    />
                                </div>
                                <div className="mt-2 flex gap-[5px] items-center">
                                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                                        Prix unitaire :
                                    </p>
                                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                                        {item.price} €
                                    </p>
                                </div>
                                <p className="text-sm font-bold text-navy-700 dark:text-white">
                                    Total : {getTotalHT([item]).toFixed(2)} €
                                </p>
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default ItemsTable;
