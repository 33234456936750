import { useEffect, useState } from 'react';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';

const OrderCard = (props: {
    deliveryStarted: boolean;
    order: any;
    onChangeOrderStatus: any;
    handleDown?: any;
    handleUp?: any;
    onSign: any;
    isExternalPage: boolean;
    index?: number;
    total?: number;
}) => {
    const {
        deliveryStarted,
        order,
        onChangeOrderStatus,
        onSign,
        isExternalPage,
        index,
        handleDown,
        handleUp,
        total,
    } = props;
    const [started, setStarted] = useState(deliveryStarted);

    useEffect(() => {
        setStarted(deliveryStarted);
    }, [deliveryStarted]);

    return (
        <div
            key={order._id}
            className="mx-[10px] mt-[10px] p-[15px] border rounded-[30px]"
        >
            {order.customer ? (
                <div>
                    <div className="flex items-center justify-between">
                        <p className="flex-grow text-lg font-bold text-navy-700 dark:text-white">
                            Commande de{' '}
                            {order.customer.displayName ||
                                order.customer.company}
                        </p>
                        {order.isReady && (
                            <span className="text-white bg-green-700 px-2 py-1 rounded-xl text-[10px]">
                                Préparée
                            </span>
                        )}
                        <div className="flex items-center">
                            {index > 0 && handleUp && (
                                <MdArrowDropUp
                                    size={30}
                                    className="hover:cursor-pointer"
                                    onClick={() => handleUp(order)}
                                />
                            )}
                            {handleDown && index + 1 !== total && (
                                <MdArrowDropDown
                                    size={30}
                                    className="hover:cursor-pointer"
                                    onClick={() => handleDown(order)}
                                />
                            )}
                        </div>
                    </div>
                    <p className="text-sm text-[#A3AED0] dark:text-white">
                        {order.customer.deliveryAddress}
                    </p>
                    <a
                        href={`https://www.google.com/maps/dir//${order.customer.deliveryAddress}`}
                        target="_blank"
                        rel="noreferrer"
                        className="text-sm text-[#0260CB] font-medium"
                    >
                        Suivre la direction
                    </a>
                    <p className="text-sm text-[#A3AED0] dark:text-white">
                        {order.customer.contact && 'Contact :'}{' '}
                        {order.customer.contact}
                        <br />
                        {order.customer.phone && 'Téléphone :'}{' '}
                        {order.customer.phone}
                    </p>
                </div>
            ) : (
                <div className="w-full text-center">Pas d'infos client !</div>
            )}
            <table className="w-full mt-[20px]">
                <thead>
                    <tr>
                        <th className="mt-[20px] w-[60%] px-[10px] text-start text-sm">
                            <p className="text-sm font-bold text-gray-600 dark:text-white">
                                Produit
                            </p>
                        </th>
                        <th className="mt-[20px] w-[40%] px-[10px] text-start text-sm">
                            <p className="text-sm font-bold text-gray-600 dark:text-white">
                                Quantité
                            </p>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {order.items.map((product: any) => {
                        return (
                            <tr className="border-t border-gray-200 h-[30px]">
                                <td className="px-[10px]">
                                    <p className="font-medium text-sm">
                                        {product.name}
                                    </p>
                                </td>
                                <td className="px-[10px]">
                                    <p className="font-medium text-sm">
                                        {product.quantity} {product.unity}
                                    </p>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {order.customerNotes && (
                <p className="mt-[10px] text-sm text-toola-500 dark:text-white">
                    ⚠️ <span className="underline">Note du client :</span>{' '}
                    {order.customerNotes}
                </p>
            )}
            <div className="flex mt-[10px] justify-center gap-[20px]">
                {!isExternalPage && order.status === 'delivered' && (
                    <button
                        className={`flex h-[34px] items-center justify-center rounded-[15px] py-1 px-3 text-center text-xs font-medium text-white transition duration-200 leading-none
                                    ${'bg-toola-500 hover:bg-toola-600 active:bg-toola-700'}`}
                        onClick={() => {
                            onChangeOrderStatus(order);
                        }}
                    >
                        {`Marquer comme non livrée`}
                    </button>
                )}
                {((onSign && started) || (onSign && !isExternalPage)) && (
                    <button
                        className="linear flex cursor-pointer items-center justify-center rounded-[15px] border border-[#0260CB] px-2 py-1 text-xs text-[#0260CB] transition duration-200 hover:bg-brand-600/5 active:bg-red-100 dark:active:bg-red-50 dark:active:opacity-80 h-[34px]"
                        onClick={() => onSign(order)}
                    >
                        Signer le bon de livraison
                    </button>
                )}
                {isExternalPage && !started && order.status === 'pending' && (
                    <p className=" text-sm align-center items-center justify-center">
                        Vous devez démarrer la livraison avant de faire signer
                        le BL.
                    </p>
                )}
            </div>
        </div>
    );
};

export default OrderCard;
