import { useDisclosure } from '@chakra-ui/hooks';
import DeleteModal from 'components/actions/Delete';
import Card from 'components/card';
import {
    archiveCustomer,
    getCustomerPage,
    shareMagicLink,
} from 'interfaces/customer';
import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import CustomerStats from './components/customerStats';
import Tasks from './components/Tasks';
import CustomerComments from './components/Comments';
import CustomerInfoPreviewPU from './components/pop-up/CustomerInfoPreview';
import { MdOpenInNew, MdOutlineShare, MdSettings } from 'react-icons/md';
import ShareOrderModal from './components/shareOrderModal';
import { toast } from 'react-toastify';

const CustomerPopUp = (props: { id?: string; onPageChange: any }) => {
    const { id, onPageChange } = props;
    const navigate = useNavigate();
    const params = useParams();
    const { isOpen, onClose } = useDisclosure();
    const [customer, setCustomer] = useState<any>(null);
    const [disabledCommandPage, setDisabledCommandPage] = useState(false);
    const {
        isOpen: isOpenShare,
        onOpen: onOpenShare,
        onClose: onCloseShare,
    } = useDisclosure();

    const initCustomer = async (id: string) => {
        const res = await getCustomerPage(id);
        console.log(res);
        if (res) {
            setCustomer(res);
            return;
        }
        //navigate("/customers");
    };

    const handleSendCommandPage = async () => {
        if (disabledCommandPage) {
            toast.error("L'accès boutique a déjà été envoyée récemment.");
            return;
        }
        setDisabledCommandPage(true);
        const res = await shareMagicLink(customer.mail);
        if (res) {
            setDisabledCommandPage(true);
            toast.success(`Accès boutique envoyée à ${customer.mail}`);
        }
        onCloseShare();
    };

    useEffect(() => {
        if (localStorage.getItem('token') === null) {
            navigate('/login');
        }
        initCustomer(params.id || id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="grid grid-cols-12 gap-3">
                <CustomerStats />
                <DeleteModal
                    isOpen={isOpen}
                    onClose={onClose}
                    description="Êtes-vous sûr de vouloir archiver ce client ?"
                    context="un client"
                    archived={true}
                    deleteFunction={() => {
                        archiveCustomer(params.id);
                        navigate('/customers');
                    }}
                />
                <ShareOrderModal
                    isOpen={isOpenShare}
                    onClose={onCloseShare}
                    onSend={handleSendCommandPage}
                    magicLink={customer && customer.magicLink}
                />
                <CustomerInfoPreviewPU
                    customer={customer}
                    onPageChange={onPageChange}
                />
                <Card
                    extra={
                        'w-full !p-4 border col-span-12 sm:col-span-6 relative'
                    }
                >
                    {/* Header */}
                    <div className="relative w-full px-[8px]">
                        {customer && customer.mail && (
                            <div className="absolute right-0 top-0">
                                <button
                                    className="flex items-center gap-1 text-sm text-gray-700"
                                    onClick={() =>
                                        navigate('/settings/command-page')
                                    }
                                >
                                    <MdSettings />
                                    <span>Réglages</span>
                                </button>
                            </div>
                        )}
                        <h4 className="text-lg font-medium text-navy-700 dark:text-white">
                            Boutique B2B
                        </h4>
                        <p className="mt-1 text-sm text-gray-600">
                            Il s'agit de l'espace de commande dédié à ce client.
                        </p>
                    </div>
                    {customer && customer.mail ? (
                        <div className="mt-2 grid grid-cols-1 sm:grid-cols-3 gap-2">
                            <button
                                className="linear flex cursor-pointer items-center justify-center rounded-xl border border-[#01499b] px-2 py-1 text-xs text-[#01499b] transition duration-200 hover:bg-brand-600/5 active:bg-red-100 dark:active:bg-red-50 dark:active:opacity-80"
                                onClick={() =>
                                    window.open(
                                        `https://app.toola.eu/command/${customer?.magicLink}`,
                                        '_blank',
                                    )
                                }
                            >
                                <MdOpenInNew className="h-3 w-3 mr-1" />
                                Visiter
                            </button>
                            <button
                                className="linear flex cursor-pointer items-center justify-center rounded-xl border border-[#01499b] px-2 py-2 text-xs text-[#01499b] transition duration-200 hover:bg-brand-600/5 active:bg-red-100 dark:active:bg-red-50 dark:active:opacity-80"
                                onClick={onOpenShare}
                            >
                                <MdOutlineShare className="h-3 w-3 mr-1" />
                                Partager la page
                            </button>
                            <button
                                className="linear flex cursor-pointer items-center justify-center rounded-xl border border-[#01499b] px-2 py-2 text-xs text-[#01499b] transition duration-200 hover:bg-brand-600/5 active:bg-red-100 dark:active:bg-red-50 dark:active:opacity-80"
                                onClick={() =>
                                    navigate(
                                        `/customer-pricing/${customer?._id}`,
                                    )
                                }
                            >
                                <MdSettings className="h-3 w-3 mr-1" />
                                Gérer les prix
                            </button>
                        </div>
                    ) : (
                        <p className="ml-2 mt-1 text-sm text-gray-600 font-bold">
                            {'>'} Renseignez l'adresse mail du prospect pour
                            activer sa boutique B2B.
                        </p>
                    )}
                </Card>
                <div className="col-span-12 sm:col-span-6">
                    {customer && customer.activities && (
                        <CustomerComments
                            id={customer?._id}
                            comments={customer?.activities}
                        />
                    )}
                </div>
                <div className="col-span-12 sm:col-span-6">
                    {customer && customer.tasks && (
                        <Tasks id={customer?._id} tasks={customer?.tasks} />
                    )}
                </div>
            </div>
        </>
    );
};

export default CustomerPopUp;
