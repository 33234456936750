import axios from 'axios';

import env from 'variables/config';
import { errorHandler } from './utils';

axios.defaults.baseURL = env.apiUrl;

const config = (isExternalPage: boolean = false) => {
    return {
        headers: {
            Authorization:
                'Bearer ' +
                localStorage.getItem(
                    isExternalPage ? 'delivery-token' : 'token',
                ),
        },
    };
};

export const getOrderDashboard = async () => {
    const res: any = await axios
        .get('/orders/dashboard', config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const getNotInvoicedOrders = async (
    page: number,
    limit: number,
    start: string,
    end: string,
    period: string | null,
    customerId: string | null,
) => {
    let query = `?page=${page}&limit=${limit}&start=${start}&end=${end}`;
    if (customerId) query += `&customerId=${customerId}`;
    if (period) query += `&period=${period}`;

    const res: any = await axios
        .get(`/orders/not-invoiced${query}`, config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const getPaginatedOrders = async (
    page: number,
    limit: number,
    status: string,
    search: string,
    isReadyStatus: string,
) => {
    const res: any = await axios
        .get(
            `/orders?limit=${limit}&page=${page}&status=${status}&search=${search}&isReadyStatus=${isReadyStatus}`,
            config(),
        )
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const getOrders = async () => {
    const res: any = await axios
        .get(`/orders?limit=500`, config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const getOrder = async (id: string) => {
    const res = await axios.get(`/orders/${id}`, config()).catch((err) => {
        return null;
    });
    if (!res) return null;
    return res.data;
};

export const resolveOrderIssue = async (id: string) => {
    const res = await axios
        .put(`/orders/${id}/resolve-issue`, {}, config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const markOrderReady = async (id: string) => {
    const res = await axios
        .put(`/orders/${id}/ready`, {}, config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const getOrderDeliveryNote = async (
    id: string,
    isExternalPage: boolean = false,
) => {
    const fileConfig: any = {
        headers: {
            Authorization: config(isExternalPage).headers.Authorization,
        },
        responseType: 'arraybuffer',
    };
    const res = await axios
        .get(`/orders/${id}/delivery-note`, fileConfig)
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return new Blob([res.data], { type: 'application/pdf' });
};

export const getOrdersByDate = async (date: string, deliveryTourId: string) => {
    const res: any = await axios
        .get(`/orders/delivery/${date}/${deliveryTourId}`, config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const getOrdersDataByDate = async (
    date: string,
    deliveryTourId: string,
    tag: string,
    page: number,
    limit: number,
) => {
    const res: any = await axios
        .get(
            `/orders/table?date=${date}&deliveryTourId=${deliveryTourId}&tag=${tag}&limit=${limit}&page=${page}`,
            config(),
        )
        .catch(errorHandler);
    if (!res) return null;
    return res.data;
};

export const getOrdersQuantity = async () => {
    const res: any = await axios
        .get(`/orders/quantity`, config())
        .catch((err) => {
            return null;
        });
    if (!res) return null;
    return res.data;
};

export const updateOrder = async (customer: any) => {
    const id = customer._id;
    delete customer._id;
    const res: any = await axios
        .put(`/orders/${id}`, customer, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const bulkUpdateOrder = async (orders: any) => {
    const res: any = await axios
        .put(`/orders/bulk`, orders, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const bulkEditOrderLotNumber = async (items: any) => {
    const res: any = await axios
        .put(`/orders/add-lot-number`, { items }, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const archiveOrder = async (id: string) => {
    const res: any = await axios
        .delete(`/orders/${id}/archive`, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const deleteOrder = async (id: string) => {
    const res: any = await axios
        .delete(`/orders/${id}`, config())
        .catch(errorHandler);
    if (res.error) return res;
    return { data: res ? res.data : null, error: null };
};

export const createOrder = async (customer: any) => {
    const res = await axios
        .post('/orders', customer, config())
        .catch(errorHandler);
    return res.data;
};
