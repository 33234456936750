import Card from 'components/card';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getProductions } from 'interfaces/productions';
import { getProducts } from 'interfaces/products';
import { useDisclosure } from '@chakra-ui/hooks';
import ProductionPreviewModal from './components/ProductionPreview';
import LastProductions from 'views/admin/main/profile/overview/components/Project';
import EventCalendar from 'components/calendar/EventCalendar';
import InputField from 'components/fields/InputField';
import ActionButton from 'components/buttons/ActionButton';
import CreateButton from 'components/buttons/CreateButton';
import GoBack from 'components/actions/GoBack';

const ViewProduction = () => {
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [product, setProduct] = useState<any>({
        date: new Date().toISOString().split('T')[0],
        quantity: 1,
        area: 'zone-a',
        lotNumber: '',
        comment: ' ',
        product: '',
        type: '',
    });
    const [products, setProducts] = useState<any[]>(null);
    const [productions, setProductions] = useState<any[]>(null);

    const handleChange = (e: any) => {
        console.log(product);
        if (!e.target) {
            setProduct({
                ...product,
                product: e,
            });
            return;
        }
        setProduct({
            ...product,
            [e.target.id]: e.target.value,
        });
    };

    const initProductions = async () => {
        const prods = await getProductions();
        if (!prods) {
            navigate('/auth/login');
            return;
        }
        setProductions(prods.data);
    };

    const initProducts = async () => {
        const prods = await getProducts();
        if (!prods) {
            navigate('/auth/login');
            return;
        }
        setProducts(prods.data);
    };

    const onProduction = async (prod: any) => {
        setProductions([prod, ...productions]);
        onClose();
    };

    const createProd = async () => {
        onOpen();
    };

    useEffect(() => {
        if (localStorage.getItem('token') === null) {
            navigate('/login');
        }
        initProductions();
        initProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <ProductionPreviewModal
                isOpen={isOpen}
                onClose={onClose}
                product={product}
                productName={
                    products?.find((p) => p._id === product.product)?.name
                }
                onProduction={onProduction}
            />
            <div className="mt-2">
                <GoBack />
            </div>
            <div className="mt-2 grid  grid-cols-12 gap-5">
                <div className={`col-span-12 mb-5 lg:col-span-6`}>
                    <Card extra={'w-full p-5 mb-3 border'}>
                        <div>
                            <h4 className="text-lg font-medium">
                                Vos productions
                            </h4>
                            <p className="mt-2 text-base text-gray-600">
                                Transformez et assemblez vos produits.
                            </p>
                        </div>
                        <div className="mt-4 grid grid-cols-12 gap-3">
                            <div className="col-span-6 gap-2">
                                <label
                                    htmlFor={`currency`}
                                    className={`text-navy-700 text-xs font-medium`}
                                >
                                    Produit*
                                </label>
                                <div className="flex h-[35px] w-full rounded-xl border border-gray-200 px-2.5 py-auto text-sm text-gray-600 outline-none">
                                    <select
                                        id="product"
                                        className="w-full bg-white"
                                        defaultValue={
                                            products && products.length > 0
                                                ? products[0]._id
                                                : '1'
                                        }
                                        onChange={handleChange}
                                    >
                                        {products && products.length > 0 ? (
                                            products?.map((product, index) => (
                                                <option
                                                    key={index}
                                                    value={product._id}
                                                >
                                                    {product.name}
                                                </option>
                                            ))
                                        ) : (
                                            <option value={'1'}>
                                                Aucun produit
                                            </option>
                                        )}
                                        <option value="">Autre</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-span-4">
                                <InputField
                                    label="Date*"
                                    placeholder="2021-09-01"
                                    value={product.date}
                                    onChange={handleChange}
                                    id="date"
                                    type="date"
                                    inputSmall={true}
                                />
                            </div>
                            <div className="col-span-2">
                                <InputField
                                    label="Quantité*"
                                    placeholder="1"
                                    id="quantity"
                                    type="number"
                                    value={product.quantity}
                                    onChange={handleChange}
                                    inputSmall={true}
                                />
                            </div>
                            <div className="col-span-6 gap-2">
                                <label
                                    htmlFor={`zone`}
                                    className={`text-navy-700 text-xs font-medium`}
                                >
                                    Zone*
                                </label>
                                <div className="flex w-full h-[35px] rounded-xl border border-gray-200 px-2.5 py-auto text-sm text-gray-600 outline-none dark:!border-white/10 dark:!bg-navy-800">
                                    <select
                                        id="area"
                                        className="w-full bg-white"
                                        onChange={handleChange}
                                    >
                                        {[
                                            { v: 'zone-a', l: 'Zone A' },
                                            { v: 'zone-b', l: 'Zone B' },
                                            { v: 'zone-c', l: 'Zone C' },
                                        ].map((item, index) => (
                                            <option key={index} value={item.v}>
                                                {item.l}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-span-6">
                                <InputField
                                    label="Numéro de lot*"
                                    placeholder="FO-0025"
                                    id="lotNumber"
                                    type="text"
                                    onChange={(e: any) => handleChange(e)}
                                    inputSmall={true}
                                />
                            </div>
                            <div className="col-span-6">
                                <InputField
                                    label="Commentaire"
                                    placeholder={
                                        'Production de Lucas à ' +
                                        new Date()
                                            .toISOString()
                                            .split('T')[1]
                                            .split('.')[0]
                                    }
                                    id="comment"
                                    type="text"
                                    onChange={handleChange}
                                    inputSmall={true}
                                />
                            </div>
                            <div className="col-span-6 mt-6">
                                <ActionButton
                                    value="Prévisualiser"
                                    extra="text-xs py-1 3xl:text-base h-[35px] bg-toola-500 hover:bg-toola-600 active:bg-toola-700"
                                    onClick={() =>
                                        product.product !== '' && createProd()
                                    }
                                />
                            </div>
                        </div>
                        {/* <div className="mt-10 grid grid-cols-12 gap-5">
                            <CreateButton
                                value="Planifier une production (prochainement)"
                                extra="col-span-6 bg-gray-400 hover:bg-gray-400 active:bg-gray-400 disabled dark:bg-navy-800 h-10 text-xs"
                            />
                            <CreateButton
                                value="Lancer une production"
                                extra="col-span-6 h-10 text-xs"
                                onClick={() => navigate("/create-product")}
                            />
                        </div> */}
                    </Card>

                    {productions &&
                        productions.filter((p) => p.status === 'scheduled')
                            .length > 0 && (
                            <Card className="rounded-xl border border-gray-200">
                                <div className="grid grid-cols-12 gap-5 py-5">
                                    <p className="col-span-6 my-auto ml-5 text-lg">
                                        Vous avez{' '}
                                        {productions
                                            ? productions.filter(
                                                  (p) =>
                                                      p.status === 'scheduled',
                                              ).length
                                            : 0}{' '}
                                        productions planifiées
                                    </p>
                                    <CreateButton
                                        value="Visualiser"
                                        extra="my-auto col-span-5 bg-toola-500 hover:bg-toola-600 active:bg-toola-500 disabled dark:bg-navy-800 h-10 text-sm"
                                        onClick={() =>
                                            navigate('/planned-production')
                                        }
                                    />
                                </div>
                            </Card>
                        )}
                    <div className="col-span-12 h-[500px] lg:h-[58%]">
                        {!isOpen && (
                            <EventCalendar
                                initialDate={new Date().toISOString()}
                                calendarData={
                                    productions &&
                                    productions.map((prod) => {
                                        return {
                                            title: `${prod.productName} x ${prod.quantity}`,
                                            borderColor: 'transparent',
                                            start: prod.date
                                                ? prod.date.split('T')[0]
                                                : '',
                                            end: prod.date
                                                ? prod.date.split('T')[0]
                                                : '',
                                            backgroundColor:
                                                prod.status === 'scheduled'
                                                    ? '#FF973B'
                                                    : '#0260CB',
                                            className: 'warning', //error, warning, success
                                            extra: 'text-xs',
                                        };
                                    })
                                }
                            />
                        )}
                    </div>
                </div>
                <div className={`col-span-12 lg:col-span-6`}>
                    <div className={`col-span-12 mb-2 lg:col-span-6`}>
                        <Card extra={'w-full p-5 mb-3 border'}>
                            <div>
                                <h4 className="text-lg font-medium">
                                    Préparation des productions
                                </h4>
                                <p
                                    className="mt-2 text-base text-toola-500 hover:cursor-pointer hover:text-toola-600 hover:underline"
                                    onClick={() =>
                                        navigate('/stock/prepare-production')
                                    }
                                >
                                    Accéder à la préparation des productions
                                </p>
                            </div>
                        </Card>
                    </div>
                    <div className="col-span-12">
                        <LastProductions productions={productions} />
                    </div>
                    {/* <div className="col-span-12">
                        <ProductionTips />
                    </div> */}
                </div>
            </div>
        </>
    );
};

export default ViewProduction;
